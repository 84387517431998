@import "../../styles/colors.scss";

body{
    background: $background;
    color: $summer_night;
}

.login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    background: #E4ECFD;

    .gt_ps {
        color: $text_helper;
    }

    &_text{
        margin-top: 24px;
        margin-bottom: 40px;
    }

    &_content {
        margin-left: 2px;
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &_logo {
        float: left;
        width: 53px;
        height: 54px;
    }

    &_btn_logo {
        width: 33.684px;
        height: 33.429px;
    }

    &_title{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
    }

    &_cart{
        width: 432px;
        border-radius: 12px;
        background: $white;
        box-shadow: 0px 24px 48px 0px rgba(230, 235, 245, 0.40), 0px 1px 4px 0px #E3EBFC;
        display: inline-flex;
        padding: 56px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
    }

    &_cart_local{
        padding:  40px 32px;
        width: 384px;
    }

    &_user_icon{
        width: 64px;
        height: 64px;
    }

    &_icon_block{
        padding: 24px;
    }
    &_local{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        .pass_block{
            position: relative;

            .pass_icon{
                position: absolute;
                top: 16px;
                right: 16px;
            }
        }

        &_input{
            display: flex;
            width: 320px;
            height: 56px;
            padding: 19px 16px;
            align-items: center;
            border-radius: 8px;
            background-color: #F5F7FA;
            border: none;
        }

        &_error{
            background: #F8DEE4;
        }

    }

    &_btn{
        margin-top: 24px;
        width: 320px;
        padding: 16px 40px;
    }
}
