.anno_content{

  display: flex;
  justify-content: center;

  .adm_mr{

  }

  .anno_table{
    display: flex;
    flex-direction: row;
    border-radius: 12px;
    background: var(--White, #FFF);
    box-shadow: 0px 6px 16px 0px rgba(230, 235, 245, 0.80);
    padding-right: 24px;
  }

  .files_block{
    width: 291px;
    border-right: 1px solid #D2DFFB;
    max-height: 650px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &_item{
      padding: 12px 24px;
      width: 100%;
      gap: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .mod_circles{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      &_text{
        text-align: start;
        word-break: break-all;
        height: 18px;
        width: 155px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &_item.active {
      background-color: #E5EEFF;
    }

    &_header{
        padding-top: 28px;
        padding-left: 24px;
        margin-bottom: 14px;
    }
  }
  .annotation_main{
    display: flex;
    flex-direction: row;

    .annotation_btns{
      padding-top: 24px;
      padding-left: 24px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .drawing_tool{
        width: 48px;
        height: 32px;
        border-radius: 8px;
        display: flex;
        padding: 8px 16px;
        align-items: center;
        background-color: #D2DFFB;
      }
      .drawing_tool.active{
        background-color: #0D4CD3;
        svg path {
          fill: white;
        }
      }
    }
  }
  .annotation_block{
    min-width: 800px;
    min-height: 650px;
    max-width: 70rem;
    max-height: 70rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 24px;
    gap: 12px;
    padding-left: 24px;

    .anno_image{
      max-width: 60rem;
      max-height: 50rem;
    }
    .class_element{
      display: flex;
      border-radius: 4px;
      height: 36px
    }

    .main_color{
      width: 8px;
      border-radius: 4px 0 0 4px;
    }

    .op_color{
      padding: 7px 12px;
      border-radius: 0 4px 4px 0;
    }

    .class_btn_block{
      display: flex;
      flex-direction:row ;
      justify-content: start;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      margin-bottom: 48px;
    }

    .cat_button.active{
      border-radius: 7px;
      border: 2px solid blue;
    }

    .confirm_btn{
      //margin-bottom: 24px;
      margin-bottom: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    .del_btn{
      background-color: #EE3F58;
    }
  }

  #meta-info:disabled{
    background-color: #FFFFFF;
    color: #AFB2B5;
  }
  .meta_disabled{
    border-color: #AFB2B5;
  }

}

.tool_box{
  justify-items: end;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.65fr;
  align-items: center;

  .wand_params{
    width: 100px;
    padding-right: 10px;

    .ds_create_form_input{
      text-align: center;
    }
  }

}

.a9s-annotation.editable .a9s-inner.custom_add_cursor{
  cursor:url('../../assets/icons/circle-plus.svg') 10 10, default !important;
}

.a9s-annotationlayer .custom_add_cursor{
  cursor:url('../../assets/icons/circle-plus.svg') 10 10, default !important;
}

.a9s-annotation.editable.selected.delete_point {
  g {
    g {
      circle:hover {
        fill: red !important;
      }
    }
  }
}

