
.binary_classifier{

  .response_result{
    display: flex;
    padding: 4px 0px 4px 24px;
    border-radius: 12px;
    background: #FFF;
    width: 100%;
    /* Тень под всплывашки */
    box-shadow: 0px 1px 4px 0px #C2C3C5;
    align-items: center;
    gap: 32px;
    margin-bottom: 32px;
  }
}
