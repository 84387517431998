
.sl_content{
  display:flex;
  flex-direction: column;
  align-items: center;

  height: 100vh;
  background: #E4ECFD;

  .sl_data{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    width: 45%;
    min-width: 600px;
  }
  .sl_header{
    margin-top:56px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    width: 306px;
    margin-bottom: 48px;
  }

  .sl_org_list{
    margin-top: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 50px;
  }

  .sl_org_item{
    display: flex;
    flex-direction: row;
    padding: 32px;
    align-items: center;
    justify-content: space-between;

    border-radius: 12px;
    background: #FFF;

    //box-shadow: 0 6px 16px 0 #e6ebf5cc, 0 1px 4px 0 #e3ebfc;
    box-shadow: 0px 1px 4px 0px #E3EBFC, 0px 24px 48px 0px rgba(230, 235, 245, 0.40);

    &_name{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
    }

    .arrow{
      width: 24px;
      height: 24px;
    }
  }
}
.sl_org_item:hover{
  background: #D2DFFB;
  opacity: 1;
}
