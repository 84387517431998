
.settings_requirements{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;


  .settings_processing{
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(---Solitario, #E4ECFD);
    width: 100%;
  }
  .stats{
    display: flex;
    flex-direction: row;
    gap:8px;
  }

  .settings_checks{
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(---Solitario, #E4ECFD);

    &_header{
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    &_stats{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
    }
  }

  .checks_show_block{
    display: flex;
    flex-direction: column;

    .validate_progress{
      padding: 14px 24px 3px;
      margin-bottom: 12px;
    }

    .cheks_contol_btn{
      display: flex;
      flex-direction: row;
      gap: 24px;
      margin-bottom: 12px;
    }
    .add_cheks{
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      padding-left: 24px;
      gap: 4px;
      margin-top: 8px;
    }
    .checks_hide{
      display: flex;
      flex-direction: row;
      justify-content: end;
      padding: 0 24px;

      .hide_btn{
        display: flex;
        flex-direction: row;
      }
    }
    .progress_block{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;

      &__progress-bar {
        width: 300px;
        height: 14px;
        border-radius: 8px;
        border: 1px solid var(--Base-Tagline-Hit, #D2DFFB);
        background-color: #ECF0F8;
        display: flex;
      }

      &__upload-bar {
        background-color: #00A3FF;
        width: 50%;
        border-radius: 100px;
      }

    }
  }

  .checks_start_btn{
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
  }

  .files_info{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
