

.upload_page{
  width: 100%;
  height: 100%;
  padding: 50px 32px 24px 96px;

  .content_block{
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  .back_upload{
    display: flex;
    flex-direction: column;
    gap: 16px;

    .back_btn{
      word-break: break-all;
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 8px;
      margin-bottom: 10px;
    }
  }
}
