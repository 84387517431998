
.rs-anim-fade .rs-anim-in .placement-bottom-start .rs-picker-popup-daterange .rs-picker-popup{
  z-index: 999;
}
.rs-picker-toolbar{
  flex-direction: column;
  align-items: end !important;
}
.rs-picker-popup .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar-ranges {
  width: 240px !important;
}
.rs-picker-toolbar-right{
  width: 100%;
  display: flex;
  justify-content: end;
}

/*
.rs-calendar-table-cell-un-same-month {
  .rs-calendar-table-cell-content{
    display: none !important;
  }
}
*/
