

.petition{
  background: #FFF;
  box-shadow: 10px 4px 12px 0px rgba(177, 187, 218, 0.2);
  border-radius: 8px;
  padding: 24px;
  min-width: 980px;
  width: 60%;

  .legend_block{
    display: flex;
    gap: 32px;
    padding-left: 80px;
    padding-bottom: 15px;

    .legend_item{
      display: flex;
      gap: 12px;
      align-items: center;
    }
    .legend_count{
      width: 40px;
      height: 8px;
      border-radius: 4px;
      background: #B2CAEB;
    }
    .legend_up{
      width: 40px;
      height: 8px;
      border-radius: 4px;
      background: #E11432;
    }
  }


  .infinite-scroll-component {
    max-height: 800px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;

    .petition_item {
      display: flex;
      border-radius: 8px;
      background: #EDF0F6;
      flex-direction: column;
      padding: 16px;
      justify-content: center;
      align-items: start;
      align-self: stretch;
      margin-right: 5px;
      gap: 8px;

      &_info{
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  .petitions_loading{
    width: 100%;
    height: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
