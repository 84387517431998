

.settings_block{
  height: 80vh;
  width: 25%;
  min-width: 445px;
  background-color: #fff;
  display: flex;
  padding: 16px 16px 92px 16px;
  flex-direction: column;
  align-items: start;
  gap: 16px;
  border-radius: 12px;

  /* Shadow GU */
  box-shadow: 0px 1px 4px 0px #E3EBFC, 0px 24px 48px 0px rgba(230, 235, 245, 0.40);


  .settings_header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .settings_menu{
    display: flex;
    width: 100%;
    padding: 6px;
    height: 40px;
    align-items: flex-start;
    border-radius: 8px;
    background: var(---Solitario, #E4ECFD);

    .settings_menu_btn{
      padding: 4px 16px;
      border-radius: 6px;
    }
    .settings_menu_btn:hover{
      opacity: 1;
    }
    .active{
      background: var(---White, #FFF);
    }
  }

  .publish_progress{
    .validate_progress{
      padding: 0 24px 14px;
    }
    .progress_block{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;

      &__progress-bar {
        width: 300px;
        height: 14px;
        border-radius: 8px;
        border: 1px solid var(--Base-Tagline-Hit, #D2DFFB);
        background-color: #ECF0F8;
        display: flex;
      }

      &__upload-bar {
        background-color: #00A3FF;
        width: 50%;
        border-radius: 100px;
      }

    }
  }
}
