

.clusters{
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 43%;
  min-width: 790px;

  .back_btn{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: start;
  }


  &_filters{
    display: flex;
    flex-direction: row;
    gap: 28px;


    &_item{
      display: flex;
      align-items: center;
      gap: 16px;

      .select_filter{
        display: flex;
        padding: 6px;
        border-radius: 24px;
        background: var(---White, #FFF);

        /* Shadow GU */
        box-shadow: 0px 1px 4px 0px #E3EBFC, 0px 24px 48px 0px rgba(230, 235, 245, 0.40);


        .select_item{
          display: flex;
          width: 89px;
          height: 28px;
          padding: 10px 16px;
          justify-content: center;
          align-items: center;
          border-radius: 16px;
        }

        .selected{
          background: #042F89;
          color: #fff;

        }
      }

      .sort_button{
        display: flex;
        width: 255px;
        height: 40px;
        padding: 0px 16px 2px 16px;
        align-items: center;
        flex-shrink: 0;
        gap:12px;

        border-radius: 24px;
        background: var(---White, #FFF);
        box-shadow: 0px 1px 4px 0px #E3EBFC, 0px 24px 48px 0px rgba(230, 235, 245, 0.40);


        .sort_by_btn{
          display: flex;
          width: 172px;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }

  .empty_clusters{
    display: flex;
    width: 780px;
    height: 683px;
    padding-bottom: 40px;
    justify-content: center;
    align-items: center;
    gap: 18px;
    border-radius: 12px;
    background: var(---White, #FFF);

    /* Shadow GU */
    box-shadow: 0px 0px 12px 0px rgba(177, 187, 218, 0.5);

    &_content{
      display: flex;
      width: 564px;
      justify-content: center;
      align-items: center;
      gap: 16px;
      flex-direction: column;
      text-align: center;
    }
  }

  .clusters_loading{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

