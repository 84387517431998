#text-content{
  padding-top: 10px;
  max-height: 600px;
  overflow-y: auto;
  line-height: 2.1;
  white-space: pre-line;

  .r6o-annotation{
    border-bottom: none;
    //padding: 0 4px;
    //border-radius: 5px;
  }
}
.annotation_text_block{
  width: 100%;
  max-width: 100%;

}
.size_change{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 12px;
  padding: 12px 0;

  .gt_btn_secondary{
    height: 32px;
    width: 49px;
  }
}

.text_tools{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.anno_content {

  .adm_mr{
    width: 95%;

    .anno_table{
      width: 100%;

      .annotation_main{
        width: 100%;

        .annotation_block{
          margin-top: 12px;
          width: 100%;
          max-width: 100%;
          min-height: 0px;
        }
      }
    }
  }
}

.summary_edit {
  width: 100%;
  margin-top: 4px;
  padding: 4px 12px;
  border-radius: 4px;
  border: none;
  box-shadow: 2px 2px 8px #e2e8f8;
}

.summary_block{
  width: 70%;
}
