

.smart_reports{
  margin-left: 64px;
  .header_block{
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-top: 24px;
    padding-right: 24px;
    padding-left: 24px;
  }

  &_body{
    display: flex;
    justify-content: space-between;
    gap: 24px;
    padding: 24px;
  }
}

@media (min-width: 2400px){
  .smart_reports{
    margin-left: 200px;
    margin-right: 200px;
  }
}
@media (min-width: 2200px) and (max-width: 2390px) {
  .smart_reports{
    margin-left: 150px;
    margin-right: 100px;
  }
}
