.modal_preview{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(11, 31, 51, 0.30);
  backdrop-filter: blur(3.4000000953674316px);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal_window{
    border-radius: 8px;
    background: #FFF;

    display: flex;
    width: 774px;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .preview_header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .preview_encoding{
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 4px;
    }

    .preview_content{
      overflow: auto;
      display: flex;
      width: 100%;
      height: 474px;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 8px;
      border: 1px solid var(---Solitario, #E4ECFD);
    }
    .btn_block{
      display: flex;
      gap: 16px;
      justify-content: center;

      button {
        width: 234px;
        height: 52px;
        padding: 14px 24px;
      }
    }
  }


}
