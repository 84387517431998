@import "../../../../../styles/colors";

.adm_mr{
  margin-left: 60px;
  display: flex;
  padding-top: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  &_title{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 36px;
  }

  &_text{
    width: 510px;
  }
  &_select{
    width: 263px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $gosblue;
  }

  &_draft{
    padding-left: 8px;
    color: $gosblue;
    width: 86px;
    border-bottom: 3px solid $gosblue;
  }

  &_publish {
    color: $inactive_text;
    text-align: start;
    padding-left: 16px;
  }

  &_btn_group{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;
  }
  &_btn{
    word-break: break-all;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .search {
    display: flex;
    width: 241px;
    height: 32px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 8px;
    border: 1px solid #D2DFFB;
    background: $white;

    box-shadow: 0px 24px 48px 0px rgba(230, 235, 245, 0.40), 0px 1px 4px 0px #E3EBFC;

    input{
      width: 191px;
      border: none;
    }

    input::placeholder{
      color: #B9B9B9;
    }
    input :active, :hover, :focus {
      outline: 0;
      outline-offset: 0;
    }
  }

  &_table{
    height: 600px;
    width: 1074px;
    border-radius: 12px;
    background: #FFF;
    display: flex;
    flex-direction: row;

    box-shadow: 0px 6px 16px 0px rgba(230, 235, 245, 0.80), 0px 1px 4px 0px #E3EBFC;

    &_left{
      height: 100%;
      width: 100%;

      &_title{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 75px;
        padding: 24px;
      }



      &_colums{
        height: 50px;
        display: grid;
        //grid-template-columns: 1fr 0.65fr 0.75fr 0.1fr;
        grid-template-columns: 1fr 0.5fr 0.65fr;
        align-items: end;
        padding: 0 14px 8px;
        border-bottom: 1px solid #D2DFFB;
      }
    }
  }
}

