

.help_btn{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: end;


  .btn_help{
    padding: 5px 12px;
    gap: 12px;
    border: none;
    border-radius: 8px;
    background: var(---White, #FFF);
    box-shadow: 0px 1px 4px 0px #E3EBFC, 0px 24px 48px 0px rgba(230, 235, 245, 0.40);
  }
}
