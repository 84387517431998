
.gt_input {
  height: 32px;
  width: 100%;
  margin-top: 4px;
  padding: 4px 12px;
  border-radius: 4px;
  border: none;


}
input::placeholder {
  color: #AFB2B5;
  /* Plain Text */
  font-family:  "Lato Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
