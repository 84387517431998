

.datasets_info{
  width: 100%;
  height: 100%;
  padding: 80px 32px 24px 96px;

  .content_block{
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
}
