.ts_annotate{
  .anno_frame{
    border:none;
    width: 100%;
  }
  .anno_content {

    .adm_mr{
      width: 95%;

      .anno_table{
        width: 100%;

        .annotation_main{
          width: 100%;

          .annotation_block{
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}

