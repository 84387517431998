
.upload_progress{
  position: fixed;
  right: 25px;
  top: 75px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 99;

  border-radius: 8px;
  background: #FFF;
  box-shadow: 2px 2px 13.2px 0px rgba(171, 188, 226, 0.30);

  .title_block{
    display: flex;
    flex-direction: row;
    gap:12px;

    img{
      width: 24px;
    }
  }
  &_close_btn{
    position: fixed;
    top: 85px;
    right: 35px;
  }

  .progress_block{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;

    &__progress-bar {
      width: 175px;
      height: 14px;
      border-radius: 8px;
      //border: 1px solid var(--Base-Tagline-Hit, #D2DFFB);
      background-color:#E4ECFD;
      display: flex;
    }

    &__upload-bar {
      background-color: #99B1E6;
      width: 50%;
      border-radius: 100px;
    }

  }

}
