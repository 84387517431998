
.pipeline{

  border-radius: 12px;
  background: #FFF;
  /* margin: 104px; */
  width: 93%;
  height: 88vh;
  margin-top: 26px;
  margin-left: 96px;
  margin-right: 32px;
  min-width: 1792px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  /* Shadow GU */
  box-shadow: 0px 1px 4px 0px #E3EBFC, 0px 24px 48px 0px rgba(230, 235, 245, 0.40);


  &_row{
    display: flex;
    gap: 16px;
  }

  &_item{
    display: flex;
    padding: 24px;
    flex-direction: column;
    gap: 16px;
    border-radius: 12px;
    border: 1px solid var(---Solitario, #E4ECFD);
    background: #FFF;
    width: 470px;
    height: 330px;
  }

  &_item:hover{
    border: 1px solid var(---Divisore, #99B1E6);
  }
}
