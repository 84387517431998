
textarea{
  scrollbar-color:#999 #F5F7FA;
  resize: none;
  width: 100%;
  overflow-y: auto;
  border: none;

  border-radius: 8px;
  background: var(--Fessura, #F5F7FA);

  color: var(--Summer-night, #0B1F33);
  /* Plain Text */
  font-family: "Lato Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
textarea:focus {
  outline: none !important;
  border: none;
}

.text_area{
  padding: 14px 16px 8px 16px;
  border-radius: 8px;
  background: var(--Fessura, #F5F7FA);
}

.textarea_count{
  text-align: end;
  width: 100%;
  color: #9DACCE;
}
