.ds_checks_block{
  //padding: 0 24px;
  //margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .ds_checks_item{
    display: flex;
    flex-direction: row;
    gap: 12px;
  }

  .ds_checks_conteiner{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .del_checks_btn{
      display: flex;
      align-items: center;
    }

    .del_checks_btn:hover{
      opacity: 1;
    }
  }
}
