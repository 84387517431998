
.add_files{
  //min-width: 1252px;
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 890px;
  height: 80vh;
  padding: 24px;
  flex-shrink: 0;
  gap: 24px;

  justify-content: space-between;
  border-radius: 12px;
  background: #FFF;

  /* Shadow GU */
  box-shadow: 0px 1px 4px 0px #E3EBFC, 0px 24px 48px 0px rgba(230, 235, 245, 0.40);


  .change_upload{
    display: flex;
    gap: 16px;
    width: 100%;
    flex-direction: column;

    &_block{
      display: flex;
      gap: 16px;
      align-self: stretch;
      width: 100%;
    }

    &_item{
      width: 24%;
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: center;
      gap: 12px;

      border-radius: 12px;
      border: 1px solid var(---Giallo-selezionato, #F8B200);
      background: var(---White, #FFF);
      box-shadow: 0px 0px 12px 0px rgba(118, 141, 191, 0.24);
    }

    .disable_item{
      border: none;
      color: #D1D5DF;
    }
  }

  .sanit_list{
    display: flex;
    flex-direction: column;
    width: 100%;

    .sanit_item{
      display: flex;
      flex-direction: row;
      align-items: end;


      .btn_set{
        display: flex;
        height: 100%;
      }

      &_select{
        width: 100%;
      }

      .delete_btn{
        display: flex;
        height: 100%;
        padding: 0 12px;
        align-items: center;
        border-right: 1px solid var(---White, #FFF);
        background: var(---Pervinca, #E5EAF5);
      }
    }

    .add_satit{
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 4px;
      margin-top: 8px;
    }
  }

  .btn_block{
    display: flex;
    gap: 16px;
    justify-content: center;

    button {
      width: 226px;
      height: 52px;
      padding: 14px 24px;
    }
  }

  .after_upload{
    display: flex;
    flex-direction: column;
    gap: 12px;

    .uploaded_files{
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      .uploaded_item{
        display: flex;
        padding: 4px 12px 4px 8px;
        align-items: center;
        border-radius: 8px;
        background: var(---White, #FFF);
        box-shadow: 0px 0px 12px 0px rgba(118, 141, 191, 0.24);
      }
    }
  }
}
