
.empty_map{
  display: flex;
  width: 780px;
  height: 575px;
  justify-content: center;
  align-items: center;
  gap: 18px;
  padding-bottom: 105px;
  &_content{
    display: flex;
    width: 564px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-direction: column;
    text-align: center;
  }
}
