@import "../../../../styles/colors";

.block-file-upload {
  margin-top: 4px;
  padding: 24px;
  width: 100%;
  background-color: #F5F7FA;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.zone-file-upload {
  height: 82px;
  width: 436px;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%2399B1E6FF' stroke-width='4' stroke-dasharray='4%2c10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  background-color: #F5F7FA;
  border-radius: 12px;
}

.upload-button {
  color: $gosblue;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.upload-button:hover {
  text-decoration-line: underline;
}


.text-file-upload{
  gap: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.file_title{
  display: flex;
  flex-direction: row;
  gap:8px;
}

.uploaded_file_item{
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.uploaded_file_list{
  width: 100%;
  margin-bottom: 12px;
  max-height: 300px;
  overflow-y: auto;
}
