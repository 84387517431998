@import "../../../styles/colors.scss";

.room-status{
    display: flex;
    padding: 48px 78px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    background: #FFF;

    box-shadow: 0px 24px 48px 0px rgba(230, 235, 245, 0.40), 0px 1px 4px 0px #E3EBFC;
}

.room-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: $summer_night;
    text-align: center;
}

.room-search{
    width: 631px;
    gap: 24px;

    &_content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
    }

    &_waiting-img{
        width: 200px;
        height: 200px;
    }
}


.room-busy{
    width: 630px;
    gap: 24px;

    &_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }

    &_busy-img{
        width: 200px;
        height: 200px;
    }

    &_btn_group{
        display: flex;
        align-items: flex-start;
        gap: 32px;
    }

}


.room-notification{
    width: 611px;
    gap: 10px;

    &_content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 43px;
    }

    &_sent-img{
        width: 184px;
        height: 151.213px;
    }

    .gt_pt{
        color: $text-helper;
    }

    &_mail{
        color: $gosblue;
    }

    &_descript{
        width: 311px;
    }
}

.room-error{
    width: 631px;
    gap: 24px;

    &_content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
    }

    &_error-img{
        width: 184px;
        height: 166.607px;
    }

}

.room-free{
    width: 631px;
    gap: 24px;

    &_content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
    }

    &_free-img{
        width: 200px;
        height: 200px;
    }

}
