
.gt_select{
  display: flex;
  height: 52px;
  width: 100%;
  background-color: #F5F7FA;
  padding: 4px 16px;
  border-radius: 8px;
  border: none;

  &_block{
    position: relative;

    .main_select{
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &_option{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &_list{
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    position: absolute;
    background-color: #F5F7FA;
    width: 100%;
    z-index: 100;
  }

}
.show_opt{
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.option_item:hover{
  background-color: #ECF0F8;
}

