@import "../../styles/colors";

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 30px;

    ul {
        margin-top: 0;
        margin-bottom: 1rem;
    }

}
.page-prev{
    background-image: url("../../assets/icons/pagination_left.svg");
    background-repeat:no-repeat;
    background-position: center center;

}
.page-prev.disabled{
    background-image: url("../../assets/icons/pagination_left_disable.svg");
}
.page-next{
    background-image: url("../../assets/icons/paginate_right.svg");
    background-repeat:no-repeat;
    background-position: center center;
}
.page-next.disabled{
    background-image: url("../../assets/icons/paginate_right_disabled.svg");
}
.page-link {

    height: 32px;
    padding: 0.375rem 0.75rem;
    margin: 3px;
    position: relative;
    display: block;
    color: $gosblue;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor: pointer;
}

.page-item{
    a {
        cursor: pointer;
    }
}
.page-item.active .page-link {
    color: #fff;
    border-radius: 8px;
    background:$active_item;

}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color:$bg-color;
    border-color: #dee2e6;
}
.page-item:first-child .page-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
