

.bach_processing{
    display: flex;
    width: 100%;
    justify-content: center;

    &_content{
      display: flex;
      width: 1068px;
      flex-direction: column;
      gap: 24px;
      margin-top: 52px;

      .bach_settings{
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        gap: 24px;
        border-radius: 12px;
        background: #FFF;
        width: 100%;
        /* Shadow GU */
        box-shadow: 0px 1px 4px 0px #E3EBFC, 0px 24px 48px 0px rgba(230, 235, 245, 0.40);

        .start_btn{
          padding: 14px 24px;
        }

        .btn_block{
          width: 100%;
          display: flex;
          justify-content: center;
        }

        &_item{
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 2px;
        }
      }


      .back_btn{
        display: flex;
        gap: 8px;
        justify-content: start;
      }
    }
}
