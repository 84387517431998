.modal_checks{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(11, 31, 51, 0.30);
  backdrop-filter: blur(3.4000000953674316px);
  display: flex;
  justify-content: center;
  align-items: center;

  &_close{
    display: flex;
    width: 100%;
    justify-content: end;
  }

  &_text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    text-align: center;
  }

  &_window{
    border-radius: 8px;
    background: #FFF;

    display: flex;
    width: 612px;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }


  &_btn_group{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}
