.check_modal{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(11, 31, 51, 0.30);
  backdrop-filter: blur(3.4000000953674316px);
  display: flex;
  justify-content: center;
  align-items: center;

  .window{
    width: 507px;
    display: inline-flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 8px;
    background: #FAFCFF;

    .close_check_modal{
      display: flex;
    }
    .checks_close_btn{
      position: relative;
      left: 243px;
      bottom: 6px;
    }

    .checks_block{
      display: flex;
      width: 445px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }
    .search {
      display: flex;
      width: 241px;
      height: 32px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;

      border-radius: 8px;
      border: 1px solid #D2DFFB;
      background: #FFFFFF;

      box-shadow: 0px 24px 48px 0px rgba(230, 235, 245, 0.40), 0px 1px 4px 0px #E3EBFC;

      input{
        width: 191px;
        border: none;
      }

      input::placeholder{
        color: #B9B9B9;
      }
      input :active, :hover, :focus {
        outline: 0;
        outline-offset: 0;
      }
    }

  }
}
