.clusters_list{
  position: relative;
  .infinite-scroll-component{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 101.5%;
    max-height: 880px;
    overflow: auto;
    scrollbar-color:#D1D5DF #FAFCFF;

    .selected_item{
      background: #EDF2FE !important;
      //width: 110%;
    }

    .clusters_list_item{
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      background: #FFFFFF;
      //box-shadow: 0px 4px 12px 0px rgba(177, 187, 218, 0.50);
      box-shadow: 0px 2px 4px 0px #C2C3C5;
      margin: 2px;
      width: 780px;

      .main_block{
        gap: 16px;

        display: flex;
        flex-direction: column;
        padding: 16px 24px;

        .cluster_title{
          width: 700px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: start;
        }
      }

      .stats_block{
        width: 100%;
        display: flex;
        gap: 16px;
        text-align: start;
      }

      .new_cont{
        display: flex;
        align-items: center;
        justify-content: end;
        width: 100%;

        .new_block{
          writing-mode: vertical-lr;
          padding: 8px 4px;
          background: #B2B9E6;
          height: 120px;
          display: flex;
          justify-content: center;
          border-radius: 0 8px 8px 0;
        }
      }

    }
  }

}


.scroll_loading{
  margin-top: 16px;
  display: flex;
  width: 99%;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #E5EAF5;
}
