
.datasets_table{
  background-color: white;
  width: 73%;
  min-width: 1252px;
  height: 80vh;
  border-radius: 12px;
  gap: 16px;
  box-shadow: 0px 1px 4px 0px #E3EBFC, 0px 24px 48px 0px rgba(230, 235, 245, 0.40);

  padding: 16px;
  display: flex;
  flex-direction: column;

  .table_container{
    height: 70vh;
    overflow-y: auto;

    .empty_folders{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      &_content{
        width: 612px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap:8px;
      }
    }
  }
  .datasets_title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title_btns{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
    }

    .cansel_filter_btn{
      display: flex;
      gap: 8px;
      color: #FF8A00;
    }
  }

  .table_main{
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 1px solid var(---Solitario, #E4ECFD);

    .left_th{
      border-left: 0px;
      border-radius: 12px 0px 0px 0px;
    }
    .search_th{
      padding: 6px 12px;
    }
    tr {
      display: grid;
      //grid-template-columns: 2.6fr 1.16fr 2fr 1.58fr 1.8fr 1.75fr;
      grid-template-columns: 3.64fr 2.64fr 2.54fr 2fr 1.97fr;
      th{
        padding: 16px;
        border-bottom: 1px solid var(---Solitario, #E4ECFD);
        border-left: 1px solid var(---Solitario, #E4ECFD);
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .left_td{
        border-left: 0;
        justify-content: start;
        gap: 12px;

        .name_copy_block{
          text-align: start;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;
          width: 100%;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

      }
      td {
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(---Solitario, #E4ECFD);
        border-left: 1px solid var(---Solitario, #E4ECFD);
      }
    }

    .dataset_stats{
      display: flex;
      flex-direction: column;
      gap: 4px;

      .stats_row{
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }

    .table_item{
      width: 100%;
    }

    .table_item.active{
      background: var(---Azzurro-alice, #EDF2FE);
    }
    .table_item:hover{
      opacity: 1;
      background: rgba(229, 238, 255, 0.30);
    }
  }

  .status_btn{
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    text-align: center;
  }

  .new_dataset_btn{
    border: 1px solid rgba(5, 59, 117, 0.40);
    background: rgba(5, 59, 117, 0.06);
    color: rgba(5, 59, 117, 0.70);
  }

  .wip_dataset_btn{
    color: var(---Gosblue, #0D4CD3);
    border: 1px solid rgba(13, 76, 211, 0.50);
    background: var(---Azzurro-alice, #EDF2FE);
  }

  .done_dataset_btn{
    color: var(---Pino-verde, #12826E);
    border: 1px solid rgba(18, 130, 110, 0.50);
    background: rgba(221, 245, 231, 0.50);
  }

  .ready_dataset_btn{
    color: var(---Foglia-di-acero, #FF8A00);
    border: 1px solid rgba(255, 138, 0, 0.50);
    background: rgba(254, 240, 204, 0.50);
  }

  .error_dataset_btn{
    color: var(---Tela-rosso, #E11432);
    border: 1px solid rgba(225, 20, 50, 0.50);
    background: rgba(252, 217, 222, 0.50);
  }

  .actions_btn{
    display: flex;
    padding: 4px 12px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(---White, #FFF);

    /* Тень под всплывашки */
    box-shadow: 0px 1px 4px 0px #C2C3C5;
  }

  .dataset_search{
    width: 100%;
    height: 100%;
    display: flex;
    padding: 8px 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(---Divisore, #99B1E6);

    input{
      border: none;
      overflow: hidden;
      color: var(---Blu-selvaggio, #9DACCE);
      text-overflow: ellipsis;
    }
    input :active, :hover, :focus{
      outline: 0;
      outline-offset: 0;
    }

    .icon_block{
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
