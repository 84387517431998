.modal{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(11, 31, 51, 0.30);
  backdrop-filter: blur(3.4000000953674316px);
  display: flex;
  justify-content: center;
  align-items: center;

  &_window{
    display: inline-flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 8px;
    background: #FAFCFF;
  }

  &_del_btn{
    background-color: #EE3F58;
  }
  &_del_btn:hover{
    background-color: #EE3F60;
  }

  &_btn_group{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
}
