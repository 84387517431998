

.class_block{
  margin-top: 4px;
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  .add_class_btn{
    background-color: #FFFFFF;
    width: 40px;
    height: 32px;
    padding: 8px 12px;
    border-radius: 4px
  }
  .input_class{
    width: 153px;

    input{
      margin-top: 0;
    }
  }

  .class_list{
    height: 100%;
    display: flex;
    gap: 12px;
    flex-flow: row wrap;
  }

  .class_element{
    display: flex;
    border-radius: 4px;
    height: 32px;
  }
}

.color_picker{
  box-shadow: 0px 24px 48px 0px rgba(230, 235, 245, 0.40), 0px 1px 4px 0px #E3EBFC, 0px 4px 9.3px 0px rgba(175, 178, 181, 0.30);

  width: 156px;
  height: 64px;
  background-color: #FAFCFF;
  padding: 8px;
  border-radius: 8px;
  border: none;

  margin-top: 4px;

  &_container{
    width: 140px;
    height: 48px;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 8px;
    gap: 6px;

    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 1px;
    grid-row-gap: 1px;
    align-items: center;
    justify-content: center;
  }

  .color_circle{
    width: 12px;
    height: 12px;
    border: #AFB2B5 solid 1px;
    border-radius: 6px;

  }

  .active_color{
    border: grey solid 2px;
  }

}


ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.right-menu{
  position: absolute;
  width: 112px;
  height: 34px;
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.right-menu-item{
  width: 100%;
  padding: 4px 12px;
  color: #EE3F58;
  display: flex;
  align-items: center;
  gap: 8px;
}

.right-menu-item:hover{
  background-color: #ECF0F8;
}

.shadow{
  box-shadow: 2px 2px 8px #EDF2FE;
}

.main_color{
  width: 8px;
  border-radius: 4px 0 0 4px;
}

.op_color{
  padding: 7px 12px;
  border-radius: 0 4px 4px 0;
}
