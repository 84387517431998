

.stats_progress{
  width: 100%;
  display: flex;
  flex-direction: column;

  .progress_text{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .progress_bar{
    display: flex;
    width: 100%;
    height: 4px;
    background-color: #E4ECFD;
    border-radius: 2px;

    .progress_with{
      border-radius: 4px;
      background-color: #99B1E6;
    }
  }
}
