@import "../../../../styles/colors";

body{
    background: $background;
    color: $summer_night;
}

.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    margin: 5px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment  {
    height: 10px;
    background-color: transparent;
}
::-webkit-scrollbar-track-piece  {
    background-color: #FFFFFF;
}
::-webkit-scrollbar-thumb:vertical {
    height: 24px;
    background-color: #D1D5DF;
    -webkit-border-radius: 16px;
    padding: 5px;
}
.ds_create{
    width: 545px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    margin-top: 24px;
    gap: 24px;
    margin-bottom: 200px;

    &_back{
        width: 100%;
        display: flex;
        gap: 8px;
        color: $gosblue;
    }

    &_form{
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;

        &_input {
            height: 32px;
            width: 100%;
            margin-top: 4px;
            padding: 4px 12px;
            border-radius: 4px;
            border: none;
        }

        &_roles{
            display: flex;
            gap: 12px;
            align-items: start;

            .role_persona_icon{
                margin-top: 4px;
                display: flex;
                width: 32px;
                height: 32px;
                padding: 2px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                background:$white;
            }
        }

        .role{
            width: 160px;
        }

        .ogrn{
            width: 320px;
        }


        .delete_role_btn{
            display:flex;
            margin-top: 4px;
            padding: 8px 12px;
            height: 32px;
            background-color: #FFFFFF;
            border-radius: 4px;
        }

        textarea{
            resize: none;
            width: 100%;
            margin-top: 4px;
            border-radius: 4px;
            padding: 8px 12px;
            overflow-y: scroll;
            border: none;
        }

        .add_role{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 4px;
            margin-top: 8px;
        }

        .input_group{
            text-align: start;
            .red {
                color: #EE3F58;
            }
        }

        .submit_block{
            display: flex;
            padding: 12px 0px;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            margin-top: 24px;
        }

    }
}

.submit_listen{
    display: none;
}
