

.modal_hotkey{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(11, 31, 51, 0.30);
  backdrop-filter: blur(3.4000000953674316px);
  display: flex;
  justify-content: center;
  align-items: center;

  &_close{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &_window{

    border-radius: 8px;
    background: #FFF;

    display: flex;
    width: 880px;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  &_btn_block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .btn_blocks{
    display: flex;
    gap: 16px;
    justify-content: end;
  }

}

.hotkey_btn_service{
  display: flex;
  height: 32px;
  padding: 4px 14px 4px 12px;
  align-items: center;
  gap: 8px;

  color: var(---Gosblue, #0D4CD3);

  border-radius: 8px;
  background: var(---Solitario, #E4ECFD);
}

.hotkey_btn_service:disabled{
  background: var(---Pervinca, #E5EAF5);
  color: var(---Interlineare, #66727F);

  svg {
    path {
      fill: #66727F;
    }
  }
}
.hotkey_btn_service:hover{
  opacity: 1;
}

.hotkeys_block{
  display: flex;
  gap: 12px;
  width: 100%;
}

.hotkeys_info{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(---Solitario, #E4ECFD);

  .hotkeys_info_item{
    width: 100%;
    display: grid;
    padding: 8px 12px;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;

    .key_block{
      display: flex;
      padding: 4px 12px;
      align-items: center;
      gap: 10px;

      border-radius: 8px;
      background: var(---Osso-grigio, #ECF0F8);

      /* Тень под всплывашки */
      box-shadow: 0px 1px 4px 0px #C2C3C5;
    }
  }
}


.redactor_btn{
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  background: var(---White, #FFF);

  /* Тень под всплывашки */
  box-shadow: 0px 1px 4px 0px #C2C3C5;
}


.redactor_btn:disabled{
  background: var(---Pervinca, #E5EAF5);

  svg {
    path {
      fill: #66727F;
    }
  }
}

.key_block_multy{
  display: flex;
  gap:4px;
  justify-content: end;
}
