

.upload_progress_block{
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 80vh;
  min-width: 890px;
  border-radius: 12px;
  padding: 24px;
  background: #FFF;

  /* Shadow GU */
  box-shadow: 0px 1px 4px 0px #E3EBFC, 0px 24px 48px 0px rgba(230, 235, 245, 0.40);

  .upload_header{
    display: flex ;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .upload_logs{
    display: flex;
    gap: 16px;
  }

  .uploads_image{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #66727F;
  }


  .upload_process{
    .title_block{
      display: flex;
      flex-direction: row;
      gap:12px;

      img{
        width: 24px;
      }
    }
    &_close_btn{
      position: fixed;
      top: 75px;
      right: 35px;
    }

    .progress_block{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;

      &__progress-bar {
        width: 300px;
        height: 14px;
        border-radius: 8px;
        border: 1px solid var(--Base-Tagline-Hit, #D2DFFB);
        background-color: #ECF0F8;
        display: flex;
      }

      &__upload-bar {
        background-color: #00A3FF;
        width: 50%;
        border-radius: 100px;
      }

    }
  }
}
