
.folder_view {
  width: 100%;
  height: 100%;
  .empty_folders {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;


    &_content {
      width: 612px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 8px;
    }
  }
}
