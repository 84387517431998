@import "../../styles/colors.scss";

.header {
    background: #FFF;
    box-shadow: 0px 24px 48px 0px rgba(230, 235, 245, 0.40), 0px 1px 4px 0px #E3EBFC;


    display: flex;
    height: 65px;
    padding: 12px 32px 12px 96px;
    justify-content: space-between;
    align-items: center;

    &_logo-link{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    &_logo{
        width: 238px;
        height: 40px;
    }

    &_user-info{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
    }

    &_left_block{
        padding-right: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;
    }

    .hr_block{
        border-right: 1px solid #E5EAF5;
        height: 26px;
        width: 2px;
    }

    &_account-icon {
        display: flex;
        width: 26px;
        height: 26px;
        justify-content: center;
        align-items: center;
    }

    &_exit_btn{
        color: #E11432;
        display: flex;
        padding: 7px 12px 7px 16px;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: #FCD9DE;
    }

    &_info_btn{
        display: flex;
    }
}

