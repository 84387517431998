@import "../../styles/colors.scss";

body{
    background: $background;
    color: $summer_night;
}

.room{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 24px;
}
