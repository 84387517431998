.settings_logs{
  width: 100%;

  .notification{
    width: 100%;
    all: unset;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 65vh;

    .log_list{
      height: 100%;
      .item{
        width: 100%;
        .text{
          word-break: break-word;
        }
      }

      .log_preview_confirm{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .log_preview_text{
          display: flex;
          gap: 8px;
        }
      }
    }

    .notification_btn_group{
      flex-direction: row;
      align-items: start;
      gap: 24px;

      button{
        display: flex;
        padding: 6px 14px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
      }
    }
  }

  .search{
    border-radius: 8px;
    border: 1px solid var(---Divisore, #99B1E6);
    display: flex;
    padding: 6px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    width: 100%;

    input{
      width: 100%;
      border: none;
    }

    input::placeholder{
      color: #B9B9B9;
    }
    input :active, :hover, :focus {
      outline: 0;
      outline-offset: 0;
    }
  }

  .empty_logs{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .empty_content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      width: 228px;
    }
  }
}
