@import "../../styles/colors.scss";

.notification{
  z-index: 10;
  position: fixed;
  right: 4px;
  top: 65px;
  width: 666px;
  height: 564px;
  padding: 32px;
  border-radius: 8px;
  background: #FAFCFF;
  box-shadow: 0px 1px 10px 0px rgba(118, 141, 191, 0.30);

  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  .log_list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    overflow-y: auto;

    .item{
      display: flex;
      width: 602px;
      padding: 7px 24px 7px 12px;
      flex-direction: row;
      align-items: center;

      border-radius: 8px;
      border: 1px solid #D2DFFB;
      background: #FFF;

      .time{
        margin-left: 8px;
      }
      .text{
        margin-left: 24px;
      }
    }
  }




  &_close_btn{
    position: fixed;
    top: 80px;
    right: 22px;
  }

  &_btn_group{
    display: flex;
    align-items: center;
    gap: 20px;

    .statuses{
      display: flex;
      align-items: center;
      gap: 8px;

      .btn{
        height: 32px;
        display: flex;
        padding: 7px 12px;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        border-radius: 4px;
        background: $white;

        box-shadow: 0px 24px 48px 0px rgba(230, 235, 245, 0.40), 0px 1px 4px 0px #E3EBFC;
      }

      .problem{
        //border: 1px solid $gosred;
      }

      .warning.active{
        background: #FEF0CC;
      }

      .problem.active{
        background: #FFE2E2;
      }

      .success{
        //border: 1px solid #2DC36A;
      }

      .success.active{
        background: #E9FFEA;
      }

      .information{
        //border: 1px solid $active_item;
      }

      .information.active{
        background: #E5EEFF;
      }
    }

    .search {
      display: flex;
      width: 241px;
      height: 32px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;

      border-radius: 8px;
      border: 1px solid #D2DFFB;
      background: $white;

      box-shadow: 0px 24px 48px 0px rgba(230, 235, 245, 0.40), 0px 1px 4px 0px #E3EBFC;

      input{
        width: 191px;
        border: none;
      }

      input::placeholder{
        color: #B9B9B9;
      }
      input :active, :hover, :focus {
        outline: 0;
        outline-offset: 0;
      }
    }
  }
}
