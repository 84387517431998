
.categories_stats{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  width: 100%;

  .cat_item{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }

  .stats_block{
    display: flex;
    gap: 16px;
    width: 100%;

    .stats_elem{
      display: flex;
      width: 50%;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .stats_row{
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}
