
.modifiers_list {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  .name_circle{
    position: relative;
    width: 30px;
    height: 30px;
    border: 1px solid #D2DFFB;
    background-color: #FFF;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    //box-shadow: 0px 1px 4px 0px #E3EBFC, 0px 24px 48px 0px rgba(230, 235, 245, 0.40);
    box-shadow: 0px 1px 4px 0px #E3EBFC, 0px 2px 4px 0px rgba(230, 235, 245, 0.4);
  }

  .modifiers_tooltips{
    display: flex;
    flex-wrap: wrap;
    gap:10px;
    max-width: 150px;
  }
}


