
.user_select{
  display: flex;
  height: 32px;
  width: 100%;
  background-color: #FFFFFF;
  padding: 4px 12px;
  border-radius: 4px;
  border: none;

  &_block{
    overflow-y: auto;
    max-height: 200px;
  }
  &_option{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

}

.option_item:hover{
  background-color: #ECF0F8;
}
