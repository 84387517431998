@import "../styles/colors.scss";

@media (max-width: 1600px) {
  body { zoom: 75%; }
}

@media (max-width: 1500px) {
  body { zoom: 65%; }
}

@media (max-width: 1200px) {
  body { zoom: 60%; }
}


body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  background-color: $bg-color;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.logo {
  width: 50px;
  height: 50px;
}

.container {
  max-width: 1110px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: content-box;
}

button {
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  transition: opacity 0.3s;
  cursor: pointer;
}

a {
  color: inherit;
  font: inherit;
  transition: opacity 0.3s;
  text-decoration: none;
}

button:hover,
a:hover {
  opacity: 0.5;
}

input {
  font: inherit;
  color: inherit;
}
