$bg-color: #e5e5e5;
$text-color: #565b85;

$gosblue: #0D4CD3;
$active_item: #1D5DEB;
$hit_blue: #0B40B3;
$summer_night: #0B1F33;
$text_helper: #66727F;
$inactive_grey: #E5EAF5;
$inactive_text: #AFB2B5;
$input: #F5F7FA;
$background: #FAFCFF;
$white : #FFFFFF;
$gosred:#EE3F58;
$dark_blue:#053B75;


