
.filter_select{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 53px;
  background-color: #FFFFFF;
  padding: 4px 16px;


  &_option{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &_row{
    .filter_hr{
      display: flex;
      justify-content: center;
    }
  }

  &_list{
    box-shadow: 0px 12px 32px 0px rgba(11, 31, 51, 0.18);
    border-radius: 8px;
    position: absolute;
    background-color: #FFFFFF;

    width: 248px;
    top: 28px;
    left: -16px;
  }

  .option_item:hover{
    background-color: #EDF2FE;
  }


}
.first_row{
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.last_row{
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}


