@import "../../styles/colors.scss";

.menu_close{
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 64px;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  background: #053B75;

  &_arrow_btn{
    border-bottom: 1px solid #E4ECFD;
    display: flex;
    width: 100%;
    padding: 12px;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
    background: #053B75;
  }

  &_item{
    display: flex;
    height: 64px;
    padding: 20px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    align-self: stretch;
  }

  &_item:hover{
    background-color: #99B1E6;
  }
}

.menu_open{
  z-index: 100;
  position: fixed;
  top: 0;
  left: -300px;
  display: flex;
  width: 288px;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  background: #053B75;
  transition: 0.5s;

  color: #E4ECFD;

  &_arrow_back{
    transform: rotate(-180deg);
  }

  &_arrow_btn{
    border-bottom: 1px solid #E4ECFD;
    display: flex;
    padding: 12px;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    background: #053B75;
  }

  &_item{
    display: flex;
    height: 64px;
    padding: 20px 16px 20px 20px;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    align-self: stretch;
  }

  &_item:hover{
    background-color: #99B1E6;
  }
}
