.modal_info{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(11, 31, 51, 0.30);
  backdrop-filter: blur(3.4000000953674316px);
  display: flex;
  justify-content: center;
  align-items: center;


  &_close{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &_text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    text-align: center;
  }

  &_window{
    border-radius: 8px;
    background: #FFF;

    display: flex;
    width: 727px;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }


}
