
.regions_map{
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  gap: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px 0px rgba(177, 187, 218, 0.50);

  .parent {
    display: grid;
    grid-template-columns: repeat(18, 1fr);
    grid-template-rows: repeat(13, 1fr);
    grid-column-gap: 2px;
    grid-row-gap: 2px;
  }

  button[class^="div"] {
    display: flex;
    width: 48px;
    height: 36px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .div1 { grid-area: 1 / 6 / 2 / 7; }
  .div2 { grid-area: 1 / 7 / 2 / 8; }
  .div3 { grid-area: 2 / 5 / 3 / 6; }
  .div4 { grid-area: 2 / 6 / 3 / 7; }
  .div5 { grid-area: 3 / 5 / 4 / 6; }
  .div6 { grid-area: 3 / 6 / 4 / 7; }
  .div7 { grid-area: 3 / 7 / 4 / 8; }
  .div8 { grid-area: 3 / 8 / 4 / 9; }
  .div9 { grid-area: 3 / 9 / 4 / 10; }
  .div10 { grid-area: 4 / 2 / 5 / 3; }
  .div11 { grid-area: 4 / 4 / 5 / 5; }
  .div12 { grid-area: 4 / 5 / 5 / 6; }
  .div13 { grid-area: 4 / 6 / 5 / 7; }
  .div14 { grid-area: 4 / 7 / 5 / 8; }
  .div15 { grid-area: 4 / 8 / 5 / 9;}
  .div16 { grid-area: 4 / 9 / 5 / 10; }
  .div17 { grid-area: 4 / 10 / 5 / 11; }
  .div18 { grid-area: 4 / 11 / 5 / 12; }
  .div19 { grid-area: 5 / 4 / 6 / 5; }
  .div20 { grid-area: 5 / 5 / 6 / 6; }
  .div21 { grid-area: 5 / 6 / 6 / 7; }
  .div22 { grid-area: 5 / 7 / 6 / 8; }
  .div23 { grid-area: 5 / 8 / 6 / 9; }
  .div24 { grid-area: 5 / 9 / 6 / 10; }
  .div25 { grid-area: 5 / 10 / 6 / 11; }
  .div26 { grid-area: 5 / 11 / 6 / 12; }
  .div27 { grid-area: 4 / 15 / 5 / 16; }
  .div28 { grid-area: 4 / 16 / 5 / 17; }
  .div29 { grid-area: 5 / 12 / 6 / 13; }
  .div30 { grid-area: 5 / 13 / 6 / 14; }
  .div31 { grid-area: 5 / 14 / 6 / 15; }
  .div32 { grid-area: 5 / 15 / 6 / 16; }
  .div33 { grid-area: 6 / 3 / 7 / 4; }
  .div34 { grid-area: 6 / 4 / 7 / 5; }
  .div35 { grid-area: 6 / 5 / 7 / 6;  }
  .div36 { grid-area: 6 / 6 / 7 / 7; }
  .div37 { grid-area: 6 / 7 / 7 / 8; }
  .div38 { grid-area: 6 / 8 / 7 / 9; }
  .div39 { grid-area: 6 / 9 / 7 / 10; }
  .div40 { grid-area: 6 / 10 / 7 / 11; }
  .div41 { grid-area: 6 / 11 / 7 / 12; }
  .div42 { grid-area: 6 / 12 / 7 / 13; }
  .div43 { grid-area: 6 / 13 / 7 / 14; }
  .div44 { grid-area: 6 / 14 / 7 / 15; }
  .div45 { grid-area: 6 / 15 / 7 / 16; }
  .div46 { grid-area: 6 / 18 / 7 / 19; }
  .div88 { grid-area: 6 / 18 / 7 / 16; }
  .div47 { grid-area: 7 / 3 / 8 / 4; }
  .div48 { grid-area: 7 / 4 / 8 / 5; }
  .div49 { grid-area: 7 / 5 / 8 / 6; }
  .div50 { grid-area: 7 / 6 / 8 / 7; }
  .div51 { grid-area: 7 / 7 / 8 / 8; }
  .div52 { grid-area: 7 / 8 / 8 / 9; }
  .div53 { grid-area: 7 / 9 / 8 / 10; }
  .div54 { grid-area: 7 / 10 / 8 / 11; }
  .div55 { grid-area: 7 / 12 / 8 / 13; }
  .div56 { grid-area: 7 / 13 / 8 / 14; }
  .div57 { grid-area: 7 / 14 / 8 / 15; }
  .div58 { grid-area: 7 / 15 / 8 / 16; }
  .div59 { grid-area: 8 / 4 / 9 / 5; }

  .div89 { grid-area: 7 / 18 / 8 / 16; }

  .div60 { grid-area: 8 / 5 / 9 / 6; }
  .div61 { grid-area: 8 / 6 / 9 / 7; }
  .div62 { grid-area: 8 / 7 / 9 / 8; }
  .div63 { grid-area: 8 / 8 / 9 / 9; }
  .div64 { grid-area: 8 / 9 / 9 / 10;  }
  .div65 { grid-area: 8 / 12 / 9 / 13; }
  .div66 { grid-area: 8 / 13 / 9 / 14; }
  .div67 { grid-area: 8 / 17 / 9 / 18; }
  .div68 { grid-area: 9 / 1 / 10 / 2; }
  .div69 { grid-area: 9 / 2 / 10 / 3; }
  .div70 { grid-area: 9 / 3 / 10 / 4;}
  .div71 { grid-area: 9 / 4 / 10 / 5; }
  .div72 { grid-area: 9 / 5 / 10 / 6; }
  .div73 { grid-area: 9 / 6 / 10 / 7; }
  .div74 { grid-area: 10 / 1 / 11 / 2; }
  .div75 { grid-area: 10 / 2 / 11 / 3; }
  .div76 { grid-area: 10 / 4 / 11 / 5; }
  .div77 { grid-area: 10 / 5 / 11 / 6; }
  .div78 { grid-area: 10 / 6 / 11 / 7; }
  .div79 { grid-area: 10 / 7 / 11 / 8; }
  .div80 { grid-area: 11 / 4 / 12 / 5; }
  .div81 { grid-area: 11 / 5 / 12 / 6; }
  .div82 { grid-area: 11 / 6 / 12 / 7; }
  .div83 { grid-area: 11 / 7 / 12 / 8; }
  .div84 { grid-area: 12 / 4 / 13 / 5; }
  .div85 { grid-area: 12 / 5 / 13 / 6; }
  .div86 { grid-area: 12 / 6 / 13 / 7; }
  .div87 { grid-area: 13 / 5 / 14 / 6; }
}


.red80{
  color: #FFFFFFDE;
  background-color: #A23042;
}
.red80:hover{
  border: 1px solid #A23042;
  background: #FFF !important;
  color: #A23042;
}

.red60{
  color: #FFFFFFDE;
  background-color: #C4475A;
}
.red60:hover{
  border: 1px solid #C4475A;
  background: #FFF !important;
  color:#C4475A;
}

.red40{
  color: #FFFFFFDE;
  background-color: #CB6A79;
}
.red40:hover{
  border: 1px solid #CB6A79;
  background: #FFF !important;
  color: #CB6A79;
}
.red20{
  background-color: #ECC0C8;
  color: #00163699;
}
.red20:hover{
  border: 1px solid #ECC0C8;
  background: #FFF !important;
  color: #00163699;
}

.color_disable{
  background-color:#D6DBE7;
}
.color_disable:hover{
  border: 1px solid #D1D6E8;
  background: #FFF !important;
  color: #00163699;
}

.blue80{
  color: #FFFFFFDE;
  background-color: #233972;
}
.blue80:hover{
  border: 1px solid #B2CAEB;
  background: #FFF !important;
  color: #00163699;
}
.blue60{
  color: #FFFFFFDE;
  background-color: #375696;
}
.blue60:hover{
  border: 1px solid #638BC8;
  background: #FFF !important;
  color: #638BC8;
}
.blue40{
  color: #FFFFFFDE;
  background-color: #638BC8;
}
.blue40:hover{
  color: #375696;
  border: 1px solid #375696;
  background: #FFF !important;
}
.blue20{
  background-color: #B2CAEB;
  color: #00163699;
}
.blue20:hover{
  border: 1px solid #233972;
  background: #FFF !important ;
  color: #233972 ;
}

.orange80{
  color: #FFFFFFDE;
  background-color:#ED5102;
}
.orange80:hover{
  color: #233972;
  border: 1px solid #ED5102;
  background: #FFF !important;
}

.orange60{
  color: #FFFFFFDE;
  background-color: #F87924;
}

.orange60:hover{
  border: 1px solid #F87924;
  background: #FFF;
  color: #F87924 !important;
}

.orange40{
  color: #FFFFFFDE;
  background-color: #FD9F5B;
}

.orange40:hover{
  border: 1px solid #FD9F5B;
  background: #FFF;
  color: #FD9F5B !important;
}

.orange20{
  background-color: #FFD0A7;
  color: #00163699;
}

.orange20:hover{
  border: 1px solid #FFD0A7;
  background: #FFF;
  color: #00163699 !important;
}
