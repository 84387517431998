
.foiv_map{
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  gap: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px 0px rgba(177, 187, 218, 0.50);



  .parent {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(9, 1fr);
    grid-column-gap: 4px;
    grid-row-gap: 4px;

    .foiv_div{
      display: flex;
      width: 118px;
      height: 52px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
    }
  }
}
