.ds_logs{
  .notification{
    all: unset;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 7px 24px 24px;
    height: 440px;

    .log_list{
      .item{
        width: 422px;

        .text{
          word-break: break-word;
        }
      }
    }

    .notification_btn_group{
      flex-direction: row;
      align-items: start;
      gap: 24px;

      .search{
        width: 256px;
      }
    }
  }
}
