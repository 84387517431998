

.settings_description{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;


  &_mark{
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    border-radius: 8px;
    border: 1px solid var(---Solitario, #E4ECFD);
  }

  &_block{
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap:12px;
    width: 100%;

    border-radius: 8px;
    border: 1px solid var(---Solitario, #E4ECFD);
  }

  .description_header{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }


  .description_block{
    .markups{
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .description{
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow-y: auto;
      height: 50vh;

      .main_desc{
        display: grid;
        grid-template-columns: 1fr 0.1fr;

        .desc_data{
          display: flex;
          flex-direction: column;
          gap: 12px;
          overflow-x: auto;

          &_item{
            display: flex;
            flex-direction: column;
            gap: 4px;

            .annot_btn{
              display: flex;
              gap:12px
            }

            .roles_list{
              display: grid;
              grid-template-columns: 1fr 1fr;
            }
          }
        }

        .detail_btn{
          display: flex;
          flex-direction: row;
          align-items: end;
          gap: 4px;
        }
      }
    }
  }
}


