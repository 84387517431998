
.load-spinner{
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0%  { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.loader_conteiner{
  width: 100%;
  display:flex ;
  justify-content:center;
  background: rgba(255, 255, 255, 0.75);
  align-items: center;
  position: absolute;
  z-index: 5;
  backdrop-filter: blur(1px);
  height: 100%;
  top: 0;
}
