@import "../../../../styles/colors";

body{
    background: $background;
    color: $summer_night;
}
.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.my_room{
    width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    margin-top: 24px;
    gap: 24px;

    &_select{
        width: 400px;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $gosblue;
    }

    &_draft{
        color: $gosblue;
        width: 100px;
        border-bottom: 3px solid $gosblue;
    }

    &_publish {
        color: $inactive_text;
        text-align: start;
        padding-left: 8px;
    }

    &_text{
        text-align: start;
        color: $text_helper;
    }

    &_btn_group{
        display: flex;
        gap: 24px;
    }
    
}
