.folders_table{
  width: 100%;
  .datasets_table{
    .table_main {
      tr {
        grid-template-columns: 5.1fr 4fr 2.44fr 1.2fr;
      }
    }
  }
}
