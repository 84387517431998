@import "../styles/colors.scss";
@font-face {
  font-family: "Lato Bold";
  src: local('Lato Black'),
    url("./fonts/Lato-Bold.ttf") format('ttf'),
    url("./fonts/Lato-Bold.woff2") format('woff2');
}
@font-face {
  font-family: "Lato Regular";
  src: local('Lato Regular'),
    url("./fonts/Lato-Regular.ttf") format('ttf'),
    url("./fonts/Lato-Regular.woff2") format('woff2');
}
@font-face {
  font-family: "Lato Medium";
  src: local('Lato Medium'),
    url("./fonts/Lato-Medium.ttf") format('ttf'),
    url("./fonts/Lato-Medium.woff2") format('woff2');
}

button:hover{
  opacity: 1;
}
.gt {
    &_tooltip{
      border-radius: 8px;
      border: 1px solid #D2DFFB;
      background: #FAFCFF !important;
      color: #0B1F33 !important;
     //box-shadow: -4px 10px 13.2px 0px rgba(171, 188, 226, 0.30);
      //box-shadow: 0px 24px 48px 0px rgba(230, 235, 245, 0.4), 0px 1px 4px 0px #E3EBFC;
      box-shadow: -1px 1px 5px -2px rgba(171, 188, 226, 0.3);
    }

    &_flex_row{
      display: flex;
      flex-direction: row;
      align-items: end;
      gap: 4px;
    }

    &_th{
      color: $text_helper;
    }

    &_gr{
      color: $gosred !important;
    }

    &_gb{
      color: $gosblue !important;
    }

    &_int{
      color: $inactive_text;
    }

    &_gdg{
      color: $dark_blue;
    }

  &_tdesc{
    font-family: "Lato Bold";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

    &_h1{
      font-family: "Lato Bold";
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
    }

    &_h2 {
        font-family: "Lato Bold";
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
      }

    &_h3 {
        font-family: "Lato Bold";
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }

      &_f3 {
        font-family: "Lato Bold";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }

    &_h4 {
        font-family: "Lato Bold";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
      }

    &_h5 {
      font-family: "Lato Bold";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    &_f6{
      font-family: "Lato Bold";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

  &_icon_btn{
    height: 24px;
  }
  &_icon_btn:hover{
    opacity: 1;
  }

  &_gu_btn {
    font-family: "Lato Medium";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  &_pt{
    font-family: "Lato Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  &_ps{
    font-family: "Lato Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &_pb{
    font-family: "Lato Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }

  &_ih{
    font-family: "Lato Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &_txs{
    font-family: "Lato Regular";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    color: var(---Interlineare, #66727F);
  }

  &_ts{
    font-family: "Lato Regular";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: var(---Interlineare, #66727F);
  }

  &_btn_primary{
    border-radius: 8px;
    background: $gosblue;
    display: flex;
    padding: 7px 20px;
    justify-content: center;
    align-items: center;

    color: $white;
    text-align: center;

  }
  &_btn_s{
    padding: 14px 16px;
    height: 40px;
  }
  &_btn_primary:hover{
    background: $active_item;
    opacity: 1;
  }
  &_btn_primary:active{
    background: $hit_blue;
  }
  &_btn_primary:disabled{
    background: $inactive_grey;
    color: #86909C;
  }

  &_btn_secondary{
    border-radius: 8px;
    border: 2px solid $gosblue;
    background: $white;
    display: flex;
    padding: 5px 20px;
    justify-content: center;
    align-items: center;

    color: $gosblue;
    text-align: center;

  }
  &_btn_secondary:hover{
    border: 2px solid $active_item;
    background: $input;
    opacity: 1;
  }
  &_btn_secondary:active{
    border: 2px solid $hit_blue;
    background: $input;
  }
  &_btn_secondary:disabled{
    background: $inactive_grey;
    border: none;
    color: $inactive_text;
  }

  &_btn_gu{
    color: $gosblue;
    text-align: center;
    display: flex;
    width: 321px;
    padding: 8px 40px;
    align-items: center;
    border-radius: 8px;
    border: 2px solid $gosblue;
    background: $white;
    gap: 15px;


  }
  &_btn_gu:hover{
    border: 2px solid $active_item;
    background: $input;
  }

  &_btn_medium{
    height: 52px;
    padding: 14px 24px;
  }
}
