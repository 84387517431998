
.custom-tooltip{
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 4px 16px 0px rgba(11, 31, 51, 0.18);
}
