
.stats_table{
  border: 1px solid var(---Solitario, #E4ECFD);
  border-radius: 12px;

  .stats_table_row{
    display: flex;

    .save_btn{
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 8px;
      align-items: center;
      padding: 4px 14px 4px 12px;
    }
  }
}

.pie_block {
  .legend_pie {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 80px;
    padding-bottom: 15px;

    .legend_item {
      display: flex;
      gap: 12px;
      align-items: center;

      .color_block{
        width: 40px;
        height: 8px;
        border-radius: 4px;
      }

      .legend_1{
        background:  #DDF5E7;
      }
      .legend_2{
        background: #FEF0CC;
      }
      .legend_3{
        background: #FCD9DE;
      }
    }
  }
}
